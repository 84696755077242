define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "AC": "AC",
    "RafaPassthroughAlarm": "{label} {reason}",
    "TOGGLE_THING": "{toggle} {thing}",
    "abbreviation": "Abbreviation",
    "above": "above",
    "ac": "A/C",
    "ac-power": "A/C power",
    "accept": "Accept {item}",
    "accept-tos": "{owner} has updated their '<a target='_blank' href='{href}'>'terms of service'</a>'.",
    "account": "account",
    "account-group": "account group",
    "account-groups": "account groups",
    "account-number": "account number",
    "accounts": "accounts",
    "acknowledge": "Acknowledge",
    "acknowledgeAbbrv": "ACK",
    "acknowledge_datetime": "Acknowledge Time",
    "acknowledged": "Acknowledged",
    "acknowledged-by": "acknowledged by {acknowledger} at {time}",
    "acknowledgedBy": "Acknowledged by {user}",
    "acknowledger": "Acknowledger",
    "acknowledgerepeat": "Press 9 to acknowledge. or 7 to repeat.",
    "action": "{count, plural, =1 {Action} other {Actions}}",
    "actionLog": "Action Log",
    "actions": {
      "changeControlGroupActive": "{active_action} {control_group} control"
    },
    "activate": "Activate",
    "activateMessage": "Would you like to activate this alarm recipient?",
    "activateUnit": "Activate This Unit",
    "activated": "activated",
    "active": "Active",
    "active-alarm": "active alarm",
    "activeAlarm": "active alarm",
    "activeAlarms": "Active Alarms",
    "active_scaffold": {
      "acknowledge": "Acknowledge",
      "acknowledger": "Acknowledger",
      "acknowledgetime": "Acknowledge Time",
      "alarmhistory": "Alarm History",
      "alarmpopup": "Alarm Popup",
      "alarms": "Alarms",
      "alarmsound": "Alarm Popup Sound",
      "alarmtime": "Alarm Time",
      "carrier": "Carrier",
      "cellphone": "Cell Phone",
      "collector": "Collector",
      "contact": "Contact",
      "create_new": "Create New",
      "customer": "Customer",
      "delay": "Delay(Min)",
      "delete": "Delete",
      "details": "Details",
      "edit": "Edit",
      "emailaddress": "Email Address",
      "emailaddresses": "Email Addresses",
      "emailphonenumber": "Email/Phone Number",
      "firstName": "First Name",
      "fri": "Fri",
      "fullnumber": "Full Number",
      "gpgneighborhood": "Gpg Neighborhood",
      "gpgremoteinstallation": "GPG Remote Installation",
      "label": "Label",
      "lastName": "Last Name",
      "location": "Location",
      "method": "Method",
      "middlename": "Middle Name",
      "mon": "Mon",
      "name": "Name",
      "number": {
        "format": {
          "delimiter": ",",
          "separator": "."
        }
      },
      "password": "Password",
      "passwordconfirmation": "Password Confirmation",
      "permissionslevel": "Permissions Level",
      "phonenumbers": "Phone Numbers",
      "resetpassword": "Reset Password",
      "sat": "Sat",
      "search": "Search",
      "sendlogs": "Send Logs",
      "shiftendtime": "Shift End Time",
      "shiftsettings": "Shift Settings",
      "shiftstarttime": "Shift Start Time",
      "show": "Show",
      "sun": "Sun",
      "text": "Text",
      "thu": "Thu",
      "timezone": "Time Zone",
      "title": "Title",
      "transmitter": "Transmitter",
      "tue": "Tue",
      "type": "Type",
      "username": "Username",
      "wed": "Wed"
    },
    "add": "Add {what}",
    "addA_ITEM_NewPumpManufacturer?": "Add {item} as a new manufacturer?",
    "addA_ITEM_NewPumpModel?": "Add {item} as a new pump?",
    "addItem": "add {item}",
    "addNewAddress": "Add New Address",
    "addemailaddress": "Add Email Address",
    "addone": "Add One",
    "addphonenumber": "Add Phone Number",
    "address": {
      "city": "city",
      "country": "country",
      "lat": "lat",
      "lng": "lng",
      "one": "Address",
      "state": "state",
      "street1": "street 1",
      "street2": "street 2",
      "zipcode": "zipcode"
    },
    "addressNotFound": "Address Not Found",
    "addressNotSet": "Address Not Set",
    "addresses": "Addresses",
    "adjustsPumpSTotalStartsAndRuntime": "Adjust pump's total starts and runtime",
    "adjustsPumpSTotalStartsAndRuntimeToMatchGivenReadings": "Adjusts pump's total starts and runtime to match given readings",
    "admin": "admin",
    "adminTools": "Admin Tools",
    "administrator": "administrator",
    "advanced": "advanced",
    "advanced-data": "advanced data",
    "advanced-monitor-panel": "Advanced Monitor Panel",
    "agcl": {
      "has-shift": "Do you want to specify shift times/days for the alarm settings?",
      "how-long-to-wait": "How long should the alarm be active before sending the alarm?  If the alarm is cleared or acknowledged before the delay is reached the notification will not be sent.",
      "how-often-to-nag": "How often should the user continue to be notified?",
      "how-to-contact": "How should the user be contacted?",
      "how-to-send": "How should the notification be sent?",
      "no-contact-method": "User does not have any phone number or email address.  Add a phone number or email address to the user before creating an alarm setting",
      "shift-setting": "Notification will be sent according to the shift start times day.",
      "should-nag": "Should the user continue to be notified if the alarm has not been cleared or acknowledged?",
      "which-types-to-receive": "Which types of notifications should the user receive?",
      "who-to-contact": "Who should receive the alarms?"
    },
    "alarm": {
      "ack": "ACK",
      "acknowledge": "Acknowledge",
      "acknowledgeConfirmation": "Acknowledge this alarm?",
      "acknowledged": "Acknowledged",
      "acknowledgedBy": "Acknowledge by {acknowledger} at {acknowledgeTime}",
      "acknowledging": "Acknowledging",
      "activeAlarms": "Active Alarms",
      "alarmlevel": "Alarm Level",
      "many": "Alarms",
      "notAlarm": "Not Alarm",
      "one": "Alarm",
      "popup": "alarm popup",
      "sendLogs": "Send Logs",
      "showAcknowledged": "Show Acknowledged",
      "showAlerts": "Show Alerts",
      "showClear": "Show Clear Messages",
      "showOnlyActive": "Show Only Active",
      "sound": "alarm sound"
    },
    "alarm-delay-seconds": "Alarm Delay (s)",
    "alarm-group": "alarm group",
    "alarm-history": "alarm history",
    "alarm-latch-active": "alarm latch active",
    "alarm-latch-buzzer-active": "alarm latch buzzer active",
    "alarm-on-type": "alarm on {type}",
    "alarm-protect-active": "alarm protect active",
    "alarm-setting": "alarm setting",
    "alarm-settings": "alarm settings",
    "alarm-sound": "alarm sound",
    "alarmActive": "Alarm Active",
    "alarmAndAlert": "Alarm and Alert",
    "alarmClear": "Alarm Clear",
    "alarmGroup": {
      "checkForOutages": "Pause Notifications During Outages",
      "clearThreshold": "Clear threshold (# of sites)",
      "clearWindow": "Clear window ({units})",
      "name": "Name",
      "outageThreshold": "Outage threshold (# of sites)",
      "outageWindow": "Outage Window ({units})"
    },
    "alarmGroupContactLink": {
      "activateMessage": "Activate this alarm recipient?",
      "allAlarmsAndAlerts": "All Alarms and Alerts",
      "delayMin": "Delay (min)",
      "newAlarmRecipient": "new alarm recipient",
      "shortActivateMessage": "Activate",
      "shortSuspendMessage": "Suspend",
      "suspendMessage": "Suspend this alarm recipient?"
    },
    "alarmGroupLabel": "{name} Alarm Settings",
    "alarmOrAlert": "Alarm or Alert",
    "alarmRecepient": "alarm recipient",
    "alarmSetting": "Alarm Setting",
    "alarmSettings": "alarm settings",
    "alarmTimeLabel": "Alarm Time",
    "alarmType": "Alarm Type",
    "alarm_and_alert": "alarm and alert",
    "alarm_messages": {
      "clear_nocomm_alarm": "Communication has been restored"
    },
    "alarmacknowledged": "Alarm acknowledged",
    "alarmasdigital": "Alarm as Digital",
    "alarmdistributor": "Alarm Distributor",
    "alarmenabled": "Alarm Enabled",
    "alarmfulltext": "{alarm_string} .at {location}",
    "alarmgrouplabel": "Alarm Group",
    "alarmgroups": "Alarm Groups",
    "alarmhistory": "Alarm History",
    "alarmhtt": "Alarm HTT",
    "alarmlevel": "Alarm Level",
    "alarmpolarity": "Alarm Polarity",
    "alarmpopup": "Alarm Popup",
    "alarmpopupsound": "Alarm Popup Sound",
    "alarms": "alarms",
    "alarmsOffUntil": "Alarms Off Until",
    "alarmsStatus": "Alarms Status",
    "alarmsTurnedOff": "Alarms turned off",
    "alarmsTurnedOffUntil": "Alarms turned off until {off_time}",
    "alarmsTurnedOn": "Alarms turned on",
    "alarmsettings": "Alarm Settings",
    "alarmslevel": "Alarms Level",
    "alert": "alert",
    "alertAgeHours": "Alert Age (Hours)",
    "all": "all",
    "all-sites": "all sites",
    "allAlarmSettings": "All Alarm Settings",
    "allAlarms": "allAlarms",
    "allDataLoaded": "All Data Loaded",
    "allLabel": "All",
    "allOfObject": "All {object}",
    "allalarms": "All Alarms",
    "allow_manual_pump_control": "Allow manual pump control",
    "allowdemandread": "Allow Demand Read",
    "allowhttviewer": "Alarm HTT Viewer",
    "allowinstallationnamechange": "Allow Installation Name Change",
    "altGas": "Alt Gas",
    "altGasAbbreviation": "Alt. Gas",
    "alwaysOn": "Always On",
    "ampDraw": "Amp Draw",
    "amps": "amps",
    "an-error-occured": "An error occured",
    "analog": {
      "alarmasdigital": "Alarm As Digital",
      "allowDemandReading": "Allow Demand Reading",
      "analogThresholdSettings": "Analog Threshold Settings",
      "below_equals_min": "Minimum Buffer",
      "calibrationSettings": "Calibration Settings",
      "callibrationInfo": "Calibration Info",
      "changeRate": "Change Rate",
      "current": "Current",
      "decimal": "Decimal",
      "decimalValue": "Decimal Value",
      "deltaAlarmThreshold": "Delta alarm threshold",
      "expansion": "Expansion",
      "extendedAnalog": "Extended Analog",
      "extendedThreshold": "Extended threshold",
      "highCal": "High Value (Calibrated)",
      "highVal": "High Value",
      "identification": "Identification",
      "lastLookupTableReading": "Last Lookup Table Reading",
      "lastReading": "Last Reading",
      "lastReadingTime": "Last Reading Time",
      "lastSensorReading": "Last Sensor Reading",
      "lookUpTableInfo": "Lookup Table Info",
      "lookupTableChangeRate": "Lookup Table Change Rate",
      "lookupUnits": "Lookup Value Units",
      "lookupValuePrimary": "Lookup Value is Primary",
      "lowCal": "Low Value (Calibrated)",
      "lowVal": "Low Value",
      "many": "Analogs",
      "max": "Maximum",
      "min": "Minimum",
      "miscOptions": "Misc Options",
      "offset": "Offset",
      "one": "Analog",
      "other": "{count} analogs",
      "packed_period": "Packed Period",
      "packed_samples": "Packed Samples",
      "precisionValue": "Precision Value",
      "resistor": "Resistor",
      "scale": "Scale",
      "sendAlarmClear": "Send Alarm Clear",
      "sensorOut": "Type",
      "showThresholdsOnGraph": "Show Thresholds on Graph",
      "slot": "Slot",
      "threshold": "Threshold",
      "threshold_status": "Threshold status",
      "threshold_type": "Threshold type",
      "units": "Units",
      "voltage": "Voltage"
    },
    "analog-output": {
      "calibrationInfo": "Calibration Info",
      "currentReading": "Current Reading",
      "description": "Description",
      "high": "High",
      "identification": "Identification",
      "low": "Low",
      "many": "Analog Outputs",
      "max": "Maximum",
      "min": "Minimum",
      "name": "Name",
      "number": "Number",
      "one": "Analog Output",
      "other": "{count} Analog outputs",
      "setValue": "Set Value ({units})",
      "units": "Units"
    },
    "analogReadings": "Analog Readings",
    "analogReadingsLabel": "{analog} Analog Readings",
    "analog_report_interval": "Analog report interval",
    "analogalarmstring": "{name} is {alarm_sub_string} {threshold_name} at {parsed_value} {units}",
    "analogdeltaalarmstring": "{name} dropped {parsed_value}{units} in {delta_time} minutes",
    "analoginputs": "Analog Inputs",
    "analogoutputs": "Analog Outputs",
    "and": "And",
    "are-you-sure-you-want-to-action": "Are you sure you want to {action}?",
    "are-you-sure-you-want-to-action-item": "Are you sure you want to {action} {item}?",
    "areYouSureYouWantToDelete": "Are you sure you want to delete?",
    "areYouSureYouWantToDeleteThisItem": "Are you sure you want to delete this {item}?",
    "areyousure": "Are you sure",
    "assign": "assign",
    "assign-selected": "assign selected",
    "at": "at",
    "atTime": "at {time}",
    "auth": {
      "account-locked": "Account locked",
      "confirmPassword": "Confirm Password",
      "default-error": "Error logging in",
      "enterCode": "Enter Verification Code",
      "enterLogin": "Enter Login",
      "enterNewPassword": "Enter New Password",
      "enterPassword": "Enter Password",
      "expired-passcode": "Code has expired, please login again.",
      "expired-password": "Your password has expired.  Please enter a new password.",
      "forgotPassword": "Forgot Password?",
      "invalid-client-credentials": "Invalid username or password",
      "invalid-passcode": "Invalid Code Entered: New Code Sent",
      "invalidCode": "Invalid Code",
      "login": "Login",
      "mustequalpassword": "must equal password",
      "passwordResetSent": "If that username exists, a link to reset your password was sent to the email on file.",
      "pleaseCheckYourEmailForPasswordResetLink": "Please check your email for the link to reset your password.",
      "pleaseSignIn": "Please Sign In",
      "requiredlength": "must be {length} characters long",
      "resendCode": "Resend Code?",
      "resetPassword": "Reset password",
      "resetPasswordRequest": "Reset Password Request",
      "resetPasswordSuccess": "Password has been changed!",
      "submitCode": "Submit Code"
    },
    "authlogic": {
      "error_messages": {
        "general_credentials_error": "Login/Password combination is not valid"
      }
    },
    "auto": "Auto",
    "available": "Available",
    "average": "Average",
    "averageAbbr": "Avg",
    "averageAbbreviation": "Avg.",
    "avg": "avg",
    "back": "Back",
    "bar_graph": "Bar Graph",
    "battery-power": "battery power",
    "below": "below",
    "billing": "Billing",
    "billing-report": "billing report",
    "board-id": "Board ID",
    "board-type": "Board type",
    "brownout": "brownout",
    "bulk-create": "bulk create",
    "bulk-create-instructions": "Fill out the template with requested information then upload a csv version of the template.",
    "bulk-create-results": "{numCreated} {items} created. {numErrors} errors",
    "buzzer-active": "buzzer active",
    "by": "by",
    "calcMapCoord": "Calculate Map Coordinates",
    "call": "Call",
    "callOff": "Call Off",
    "callOn": "Call On",
    "callPolarity": "Call Polarity",
    "callStatus": "Call Status",
    "callofftext": "Call Off Text",
    "callontext": "Call On Text",
    "cancel": "cancel",
    "cannot_display_more_than": "Cannot display data more than {period}",
    "carrier": "Carrier",
    "cause": "Cause",
    "cell": "Cell",
    "change": "change",
    "change-item": "change {item}",
    "changePassword": "Change password",
    "changes_not_saved": "Your changes are not saved",
    "changes_wont_reflect_until_rule_saved": "Your changes will not reflect until rule is updated",
    "channel": "Channel",
    "channelAbbreviation": "Ch",
    "chart": {
      "hideTable": "Hide Table",
      "showTable": "Show Table"
    },
    "check-for-outage-label": "filter notifications during mass outage",
    "check_battery_power_alarm": "Check battery power alarm",
    "check_external_power_alarm": "Check external power alarm",
    "clear": "Clear",
    "clear-outage": "clear outage",
    "clear-outage-confirm-message": "Are you sure you want to clear the outage?  This will reenable notification for all sites in the group.",
    "clear-outage-threshold": "clear outage threshold (alarms)",
    "clear-outage-window": "clear outage window (minutes)",
    "clock-error": "clock error",
    "close": "Close",
    "closeInstallation": "close installation",
    "closed": "closed",
    "closestInstallations": "closest installations",
    "closestInstallationsTitle": "Closest Installations",
    "codeVersion": "Code Version",
    "collapse_all": "Collapse all",
    "collector": {
      "many": "collectors",
      "one": "collector"
    },
    "collectorClear": "Collector Clear",
    "collectorInAlarm": "Collector In Alarm",
    "collectorSettings": "Collector Settings",
    "collectorStatus": "Collector Status",
    "collectoralarms": "Collector Alarms",
    "collectors": "Collectors",
    "color": "Color",
    "comm": "Comm",
    "comm-error": "comm error",
    "comm-valid": "comm valid",
    "commStatus": "Comm Status",
    "commStatusShortText": "Comm {status}",
    "commStatusText": "Comm Status {status}",
    "command": "{count, plural, =1 {Command} other {Commands}}",
    "commandErrored": "Error sending request",
    "commandSent": "Command sent to rtu, waiting for reply",
    "commissionNewPump": "Commission new pump",
    "communicationerror": "Communication error",
    "communicationok": "Communication ok",
    "configuration": {
      "many": "configurations",
      "one": "configuration"
    },
    "confirmRestartInst": "Are you sure you want to restart {installation}?",
    "connectedToCollector": "connected to collector",
    "connection-status": "connection status",
    "contactmethod": "Contact Method",
    "contactor-diagnostic": "contactor diagnostic",
    "contacts": "contacts",
    "contactsandalarmrecipients": "Contacts and Alarm Recipients",
    "continue": "Continue",
    "contract": "contract",
    "contractNumber": "contract number",
    "controlAction": "{count, plural, =1 {Control action} other {Control actions}}",
    "controlCommand": "Control command",
    "controlGroup": "{count, plural, =1 {Control group} other {Control groups}}",
    "controlRule": "{count, plural, =1 {Control rule} other {Control rules}}",
    "controlTrigger": "{count, plural, =1 {Control trigger} other {Control triggers}}",
    "controlType": "Control type",
    "controls": "Controls",
    "controlsSettings": "Controls settings",
    "correlateReadings": "Correlate readings",
    "counter": {
      "analogThreshold": "Analog Threshold",
      "b": "offset",
      "current_readings_as_of": "Current Readings as of",
      "daily_max": "Daily Max",
      "daily_min": "Daily Min",
      "description": "Description",
      "flow_rate_analog": "Flow Rate Analog",
      "flow_rate_divider": "Flow rate divider",
      "flow_rate_high_level": "Flow rate high level",
      "flow_rate_label_value": "Flow rate label value",
      "flow_rate_low_level": "Flow rate low level",
      "flow_rate_max_value": "Flow rate max value",
      "flow_rate_min_value": "Flow rate min value",
      "flow_rate_per_pulse": "Flow rate per pulse",
      "flow_rate_scale": "Flow rate scale",
      "flow_rate_schedule": "Flow rate schedule",
      "flow_rate_settings": "Flow rate settings",
      "flow_rate_units": "Flow rate units",
      "general_settings": "General Settings",
      "hour": "Hour",
      "last15min": "Last 15 minutes",
      "last2min": "Last 2 minutes",
      "last5min": "Last 5 minutes",
      "last_15min": "Last 15 minutes",
      "last_2min": "Last 2 minutes",
      "last_5min": "Last 5 minutes",
      "last_hour": "Last hour",
      "m": "per pulse",
      "many": "Counters",
      "minutes": "minutes",
      "mode": "Mode",
      "modeIo": "Mode IO",
      "month": "Month",
      "name": "Name",
      "number": "Number",
      "one": "Counter",
      "other": "{count} counters",
      "precision": "Precision",
      "pulseMultiplyingFactor": "Pulse multiplying factor",
      "report_downtime_after": "Report Downtime After",
      "schedule": "Counter Schedule",
      "seconds": "seconds",
      "start_of_the_day": "Start of the Day",
      "total": "Total",
      "units": "Units",
      "week": "Week",
      "yesterday": "Yesterday"
    },
    "counterConfig": {
      "adjust_readings": "Adjust readings; new meter",
      "change_m_b": "Change m, b",
      "correlate_reading_from_date": "Correlate reading",
      "many": "Counter configs",
      "one": "Counter config"
    },
    "counterRateAlarm": "{label} rate alarm. {delta} {units} in {period} minutes",
    "counterReadingsLabel": "{counter} Counter Readings",
    "counterThresholdsLabel": "Set {counter} Thresholds",
    "counter_flow_rate_label": "{counter_name} flow rate",
    "counterflowratehighalarm": "{counter_flow_rate_label} high alarm.  {counter_flow_rate_label} is {parsed_value} {units}",
    "counterflowratehighalarmclear": "{counter_flow_rate_label} high alarm clear.  {counter_flow_rate_label} is {parsed_value} {units}",
    "counterflowratelowalarm": "{counter_flow_rate_label} high alarm.  {counter_flow_rate_label} is {parsed_value} {units}",
    "counterflowratelowalarmclear": "{counter_flow_rate_label} low alarm clear.  {counter_flow_rate_label} is {parsed_value} {units}",
    "counterhighalarm": "{label} is above {level}",
    "counterlowalarm": "{label} is below {level}",
    "countersReport": "Counters Report",
    "counts": "Counts",
    "create": "create",
    "create-item": "create {item}",
    "createALog": "Create a Log",
    "createANewItem": "Create a new {item}",
    "createDashboardElement": "Create a new dashboard element",
    "createaddanother": "Create Add Another",
    "createbacktoinstallation": "Create, Go Back to Installation",
    "created-item": "created {item}",
    "createdItem": "Created {item}",
    "createmultiple": "Create Multiple",
    "createnew": "Create New",
    "createone": "Create One",
    "current": "Current",
    "current-item": "current {item}",
    "currentOn": "Current on",
    "currentReading": "Current Reading",
    "customer": {
      "alarmDistributor": "Alarm Distributor",
      "alarmHtt": "Alarm HTT",
      "allowHttViewer": "Allow htt viewer",
      "comm-loss-alarm-level": "Comm Loss Type",
      "editCustomer": "Edit Customer",
      "high-level-alarm-alarm-level": "High Level Alarm Type",
      "many": "customers",
      "militaryTimeFormat": "MilitaryTimeFormat",
      "newCustomer": "New Customer",
      "one": "customer",
      "over-pressure-alarm-level": "Over Pressure Type",
      "power-fail-alarm-level": "Power Fail Type",
      "run-dry-alarm-level": "Run Dry Type",
      "sentry-advisor-advanced-data-alarm-level": "Sentry Advisor Advanced Data Alarm Type"
    },
    "customerconfig": {
      "analognumberdisplaytype": "Analog {number} Display Type",
      "analognumberlabel": "Analog {number} Label",
      "combinedistatuscolumn": "Combine DI Status Column",
      "counternumberdisplaytype": "Counter {number} Display Type",
      "counternumberlabel": "Counter {number} Label",
      "editcustomer": "Edit Customer",
      "graphicdisplay": "Graphic Display",
      "graphicdisplays": "Graphic Displays",
      "homelocation": "Home Location",
      "maxnumberofanalogs": "Max Number of Analogs",
      "maxnumberofcounters": "Max Number of Counters",
      "maxnumberofdigitalinputs": "Max Number of Digital Inputs",
      "maxnumberofplcregisters": "Max Number of PLC Registers",
      "maxnumberofpumps": "Max Number of Pumps",
      "maxnumberofraincounters": "Max Number of Rain Counters",
      "newcustomer": "New Customer",
      "pumpnumberlabel": "Pump {number} Label",
      "showbatteryvoltage": "Show Battery Voltage",
      "sortbyname": "Sort by name",
      "tablepage": "Table Page",
      "tablepages": "Table Pages"
    },
    "customers": "customers",
    "cycles": "cycles",
    "daily": "Daily",
    "dailyPumpRuntimeThreshold": "Daily Pump Runtime Threshold",
    "dailyPumpStartThreshold": "Daily Pump Start Threshold",
    "dailyRuntimes": "Daily Runtimes",
    "dailyStarts": "Daily Starts",
    "dailyavg": "Daily Avg",
    "dailygraph": "Daily Graph",
    "dailymax": "Daily Max",
    "dailymin": "Daily Min",
    "dailyreading": "Daily Reading",
    "dailyusage": "Daily Usage",
    "danger": "Danger",
    "dashboard": {
      "detailsPrompt": "Click on an elment to view details."
    },
    "dashboardDisplay": "dashboard display",
    "dashboardElement": {
      "colspan": "Columns",
      "columns": "columns",
      "editElement": "Edit Dashboard Element",
      "elementType": "Element Type",
      "leftOffset": "Left Offset",
      "newElement": "New Dashboard Element",
      "orderNumber": "order number",
      "phoneOrderNumber": "phone order number",
      "rows": "rows",
      "rowspan": "Rows",
      "topOffset": "Top Offset",
      "xsColspan": "Phone Columns",
      "xsRowspan": "Phone Rows"
    },
    "dashboards": "dashboards",
    "data": "Data",
    "dataLog": "Data Log",
    "dataTraffic": "Data Traffic",
    "datadumplabel": "{name} Data Dump",
    "dataenddate": "Data End Date",
    "dataenddatedesc": "Displays data before and including this date",
    "datatransmissionalarm": "Data Transmission Alarm, last data received at %{last_datetime}",
    "date": "Date",
    "dateRange": "Date range",
    "dateTime": "Date & Time",
    "datetime": "Datetime",
    "day": "{count, plural, =1 {a day} other {# days}}",
    "day-of-week": "day of week",
    "dayOfWeek": "Day of week",
    "dayofweek": "Day of Week",
    "days": {
      "fri": "Fri",
      "mon": "Mon",
      "sat": "Sat",
      "sun": "Sun",
      "thu": "Thu",
      "tue": "Tue",
      "wed": "Wed"
    },
    "deactivate": "Deactivate",
    "deactivated": "deactivated",
    "decimal": "Decimal",
    "decommission": "decommission",
    "decommission-selected": "decommission selected",
    "decommissioned": "decommissioned",
    "decommissioned-item": "decommissioned {item}",
    "default": "Default",
    "default_expand": "Default Expand",
    "delay": "delay",
    "delete": "delete",
    "delete-item": "delete {item}",
    "deleted-item": "deleted {item}",
    "deleting": "deleting",
    "deltaAlarmExplanation": "This alarm threshold is for a rapid drop in 15 minutes",
    "deltadrop": "Delta Drop",
    "description": "Description",
    "destroyed-item-successfully": "deleted {item}",
    "detail": "Detail",
    "details": "details",
    "detailsPage": "Details Page",
    "detailsPageLabel": "{object} Details Page",
    "device": "Device",
    "diff": "diff",
    "differences": "Differences",
    "digital-input": {
      "alarm": "Alarm",
      "alarmAlertOrReport": "Type",
      "alarmDelay": "Alarm Delay",
      "alarmPolarity": "Alarm Polarity",
      "alert": "Alert",
      "analogPinThreshold": "Analog Pin Threshold",
      "closedDetailText": "Closed Detail Text",
      "closedText": "Closed Text",
      "falseText": "False Text",
      "identification": "Identification",
      "input": "Input",
      "many": "Digital inputs",
      "one": "Digital input",
      "openDetailText": "Open Detail Text",
      "openText": "Open Text",
      "other": "{count} digital inputs",
      "reportOnly": "Report Only",
      "reportType": "Report type",
      "sendAlarm": "Send Alarm",
      "sendAlarmClear": "Send Alarm Clear",
      "sendPumpStatus": "Send Pump Status",
      "trueText": "True Text"
    },
    "digital-output": {
      "callOffText": "Call Off text",
      "callOnText": "Call On text",
      "callPolarity": "Call Polarity",
      "many": "Digital outputs",
      "one": "Digital output",
      "other": "{count} digital outputs",
      "output": "Output",
      "plc": "PLC",
      "pulse": "Pulse",
      "pulseDuration": "Pulse duration",
      "reportType": "Report type",
      "virtual": "Virtual"
    },
    "digitalinputs": "Digital Inputs",
    "digitaloutput": "Digital Output",
    "digitaloutputs": "Digital Outputs",
    "dip-switch-fault": "dip switch fault",
    "direction": "direction",
    "disable": "disable",
    "disabled": "disabled",
    "discard": "Discard",
    "discreteinputs": "Discrete Inputs",
    "discreteoutputs": "Discrete Outputs",
    "dismiss": "Dismiss",
    "displaying": "Displaying",
    "distance": "Distance",
    "distributionFiles": "Distribution Files",
    "distributor": {
      "alarmDistributor": "Alarm distributor",
      "alarmHtt": "Alarm htt",
      "allowHttViewer": "Allow htt viewer",
      "editDistributor": "edit distributor",
      "many": "distributors",
      "militaryTimeFormat": "Military time format",
      "newDistributor": "new distributor",
      "one": "distributor",
      "sub": "sub-distributors"
    },
    "distributors": "distributors",
    "doActionToItem?": "Are you sure you want to {action} {item}?",
    "doYouWantTo": "Do you want to",
    "documents": "Documents",
    "download": "Download",
    "download-item": "download {item}",
    "downloadData": "Download Data",
    "downloadReport": "Download Report",
    "download_ITEM": "Download {item}",
    "downloading": "Downloading",
    "downloadreport": "Download Report",
    "dragOrClickToUploadFile": "Drag here or click to upload a file",
    "dragOrClickToUploadImage": "Drag here or click to upload an image",
    "dry-remote-diagnostic": "dry remote diagnostic",
    "duplex": "Duplex",
    "duplex-test-mode": "duplex test mode",
    "duplicate": "Duplicate",
    "duration": "duration",
    "edit": "Edit",
    "edit-item": "edit {item}",
    "editAddress": "Edit Address",
    "editAlarmRule": "Edit Alarm Rule",
    "editConfirm": "Would you like to move the marker here?",
    "editCurrentPump": "Edit current pump",
    "editCustomer": "Edit Customer",
    "editDashboardElement": "Edit a dashboard element",
    "editInfo": "Edit info",
    "editItem": "edit {item}",
    "editName": "Edit Name",
    "editProfile": "Edit Profile",
    "editUser": "Edit User",
    "editalarmgroupcontactlink": "Edit Alarm Group Contact Link",
    "editanalog": "Edit Analog",
    "editanalogoutput": "Edit Analog Output",
    "editcontact": "Edit Contact",
    "editcounter": "Edit Counter",
    "effectiveDate": "Effective date",
    "elementType": "Element Type",
    "elevated": "Elevated",
    "email": "Email",
    "email-address": "email address",
    "email-addresses": "email addresses",
    "emailaddress": "Email Address",
    "emailaddresses": "Email Addresses",
    "emails": "{count, plural, =1 {Email Address} other {# Email Addresses}}",
    "enable": "enable",
    "enable-watt-comm-alarm": "Enable Watt Comm Alarm",
    "enablePopupNotifications": "Enable Popup Notifications",
    "enableSoundNotifications": "Enable Sound Notifications",
    "enabled": "enabled",
    "end": "end",
    "endDate": "End Date",
    "endDatetime": "End Datetime",
    "endofday": "End of Day",
    "entries": "entries",
    "eone": "E/One",
    "eone-phone-number": "866-539-9803",
    "error": "Error",
    "error-downloading-report": "error downloading report",
    "error-occured-please-call-for-help": "Error occured. Please call for help",
    "error-saving-record": "error saving record",
    "errorSaving": "Error Saving",
    "erroredWhileDeletingItem": "Error while deleting {item}",
    "erroredWhileSavingItem": "Error while saving {item}",
    "errors": {
      "creating-item": "Error creating {item}",
      "decomissioning-item": "Error decomissioning {item}",
      "demand-read": {
        "general": "Error executing demand read",
        "no-rtu": "Error executing demand read.  There is no RTU installed.",
        "pending": "A demand read is already pending.  Waiting for the RTU to update.",
        "timeout": "Timeout executing demand read.  Please wait until {timeoutUntil} to execute another demand read",
        "update-in-progress": "RTU update in progress, command not sent."
      },
      "errorSaving": "There was a problem when saving",
      "invalid-file": "Invalid file format",
      "saving": "Error Saving {item}",
      "saving-item": "Error saving {item}",
      "upload-error": "Error uploading file"
    },
    "ers": "email reports subscriptions",
    "esn": "esn",
    "event": {
      "causeTag": "Cause tag",
      "many": "Events",
      "one": "Event"
    },
    "eventLogs": "Event logs",
    "eventreport": "Event Report",
    "expand_all": "Expand all",
    "expansioncardslot": "Expansion Card Slot",
    "expectedOn": "Expected on",
    "explosion-proof-enabled": "explosion proof enabled",
    "exportData": "Export Data",
    "externalpowerlost": "External Power Lost",
    "externalpowerok": "External Power Ok",
    "failedToAction": "Failed to {action}",
    "falsetext": "False Text",
    "fillRequest": "Please fill in the address to update the map",
    "filters": "Filters",
    "findAddressLocation": "Find Address Location",
    "findMyLocation": "Find My Location",
    "first-name": "first name",
    "firstName": "First Name",
    "firstname": "First Name",
    "flags": "flags",
    "flowDirection": "Flows {direction}",
    "flowrate": "Flow Rate",
    "flowratedivider": "Flow Rate Divider",
    "flowratehighalarm": "Flow Rate High Alarm",
    "flowratelabel": "Flow Rate Label",
    "flowratelowalarm": "Flow Rate Low Alarm",
    "flowrateunits": "Flow Rate Units",
    "for-help-call": "for help call '<'a href=\"tel:{phoneNumber}\"'>'{phoneNumber}'<'/a'>'",
    "forgot-password": "forgot password?",
    "formatLargeReadings": "format large readings",
    "fri": "Fri",
    "friday": "friday",
    "from": "From",
    "fullName": "Full Name",
    "gallons": "Gallons",
    "gas": "Gas",
    "generalSettings": "General Settings",
    "generateStartsAndRuntimeFromEvents": "Generate starts and runtime from events",
    "goBack": "Go back",
    "gpgNeighborhood": {
      "alarmClearText": "Alarm clear text",
      "alarmTrueText": "Alarm true text",
      "many": "GPG Neighborhoods",
      "maxRuntime": "Max runtime",
      "maxStarts": "Max starts",
      "one": "GPG Neighborhood"
    },
    "gpgRemoteInstallations": "GPG Remote Installations",
    "gpgSingleRunAlarm": "Pump {pump_num} excessive run of {runtime} minutes",
    "gpg_comm": "Comm Alarm",
    "gpg_di": "DI Alarm",
    "gpg_neighborhood": "Gpg neighborhood",
    "gpg_pump_stats": "Pump Stat Alarm",
    "gpg_single_run": "Single Run Alarm",
    "gpgduplexstatsalarm": "Pump Usage Alarm. Pump 1 {p1_runtime} min runtime and {p1_starts} starts. Pump 2 {p2_runtime} min runtime and {p2_starts} starts.",
    "gpgneighborhood": "Gpg Neighborhood",
    "gpgneighborhoods": "GPG Neighborhoods",
    "gpgstatsalarm": "Pump Usage Alarm. Pump 1 {p1_runtime} min runtime and {p1_starts} starts.",
    "gpm": "gpm",
    "graph": "Graph",
    "group": "group",
    "guardian": {
      "collectorLabel": "{collector} (channel: {channel},  slot: {slot})",
      "many": "guardians",
      "notConnectedToCollector": "Not connected to a collector",
      "one": "Guardian"
    },
    "guardianClear": "Guardian Clear",
    "guardianCommAlarm": "Comm Alarm",
    "guardianDiAlarm": "DI Alarm",
    "guardianId": "Guardian ID",
    "guardianPumpStatAlarm": "Pump Stat Alarm",
    "guardianRegistered": "Guardian {serial_number} registered",
    "guardianSettings": "Guardian Settings",
    "guardianSingleRunAlarm": "Single Run Alarm",
    "guardianToReplace": "Guardian to Replace",
    "guardianalarms": "Guardian Alarms",
    "guardians": "Guardians",
    "hand": "hand",
    "hardware": {
      "activationdate": "Activation Date",
      "alternateid": "Alternate ID",
      "batteryvoltagealertlevel": "Battery Voltage Alert Level",
      "codeversion": "Code Version",
      "codeversions": "Code Versions",
      "editGpgCodeVersion": "Edit GPG Code Version",
      "editGpgHardwareType": "Edit GPG Hardware Type",
      "editModemType": "Edit Modem Type",
      "editSatelliteUnitType": "Edit Satellite Unit Type",
      "editStackupType": "Edit Stackup Type",
      "editcodeversion": "Edit Code Version",
      "editgpgremote": "Edit GPG Remote",
      "editmodem": "Edit Modem",
      "editsatelliteunit": "Edit Satellite Unit",
      "editstackup": "Edit Stackup",
      "gpg_code_version": "GPG Code Version",
      "gpg_hardware_type": "GPG Hardware Type",
      "gpgcodeversions": "GPG Code Versions",
      "gpghardwaretypes": "GPG Hardware Types",
      "hardwarelabel": "Hardware",
      "hardwaretypes": "Hardware Types",
      "httcodeversions": "HTT Unit Code Versions",
      "manufacturer": "Manufacturer",
      "modem": "Modem",
      "modems": "Modems",
      "modemtype": "Modem Type",
      "modemtypes": "Modem Types",
      "newGpgCodeVersion": "New GPG Code Version",
      "newGpgHardwareType": "New GPG Hardware Type",
      "newModemType": "New Modem Type",
      "newSatelliteUnitType": "New Satellite Unit Type",
      "newStackupType": "New Stackup Type",
      "newcodeversion": "New Code Version",
      "newgpgremote": "New GPG Remote",
      "newmodem": "New Modem",
      "newsatelliteunit": "New Satellite Unit",
      "newstackup": "New Stackup",
      "provisioning": "Provisioning",
      "satelliteunits": "Satellite Units",
      "satelliteunittype": "Satellite Unit Type",
      "satelliteunittypes": "Satellite Unit Types",
      "serialnumber": "Serial Number",
      "stackup": "Stackup",
      "stackups": "Stackups",
      "stackuptype": "Stackup Type",
      "stackuptypes": "Stackup Types",
      "status": "Status",
      "surname": "Surname",
      "transmissiontype": "Transmission Type",
      "unknownTransmissionType": "Unknown Transmission Type",
      "useserialnumberassurname": "Use Serial Number As Surname",
      "version_number": "Version Number"
    },
    "hardwareInfo": "Hardware Info",
    "hardwarereset": "Hardware Reset",
    "height": "Height",
    "hide": "Hide",
    "high": "High",
    "high-level-alarm": "high-level-alarm",
    "high-level-alarm-active": "high level alarm active",
    "high-level-alarm-clear": "high level alarm clear",
    "high-level-alarm-pending": "high level alarm pending",
    "high-level-alarm-unknown": "high level alarm unknown",
    "highalarm": "High Alarm",
    "highcal": "High Calibration",
    "highval": "High Input Value",
    "history": "History",
    "home": "home",
    "homePage": "Home Page",
    "hourly": "Hourly",
    "hourlygraph": "Hourly Graph",
    "hours": "Hours",
    "hrs": "hrs",
    "httMenu": "HTT Menu",
    "htt_admin": {
      "bill_customer": "Bill Customer",
      "billing": "Billing",
      "cost": "Cost",
      "created_at": "Created At",
      "date_not_set": "Date Not Set",
      "disablemanualcontrol": "Disable Manual Control",
      "due_this_month": "Due This Month",
      "editbillinginfo": "Edit Billing Info",
      "failure_reason": "Failure Reason",
      "lookupvalueprimary": "Lookup Value Primary",
      "message_id": "Message ID",
      "overdue": "Overdue",
      "renew_one_year": "Renew 1 Year",
      "renewal_month": "Renewal Month",
      "renewal_year": "Renewal Year",
      "reprocess": "Reprocess",
      "retail": "Retail",
      "set_marquee": "Set Marquee",
      "surname": "Surname",
      "unknown_messages": "Unknown Messages",
      "wholesale": "Wholesale"
    },
    "htt_id": "HTT ID",
    "id": "ID",
    "identifier": "Identifier",
    "imei": "imei",
    "inAlarm": "In Alarm",
    "inactive": "Not Active",
    "individual_digital_output_control": "Individual digital output control",
    "info": "Info",
    "input": "Input",
    "install": "Install {what}",
    "install-item": "install {item}",
    "installNewGuardian": "Install a New Guardian",
    "installPending": "Install Pending",
    "installRtu": "Install Rtu",
    "installation": {
      "AC": "AC",
      "alertAgeHours": "Alert age hours",
      "all": "Installations",
      "allowManualPumpControl": "Allow manual pump control",
      "alternate": "Alternate",
      "analogPowerModes": {
        "always_off": "off",
        "always_on": "on",
        "cycle": "cycle"
      },
      "analogPowerSetting": "Analog power setting",
      "analogReportInterval": "Analog report interval",
      "billCustomer": "Bill Customer",
      "billDistributor": "Bill Distributor",
      "billFrequency": "Bill Frequency",
      "billingNotes": "Billing Notes",
      "billingPage": "Installation Billing",
      "billingSuspended": "Billing Suspended",
      "checkBatteryPowerAlarm": "Check battery power alarm",
      "checkExternalPowerAlarm": "Check external power alarm",
      "connnectionInformationReportInterval": "Connection information report interval (seconds)",
      "controlMode": "Control Mode",
      "counterReportInterval": "Counters report interval (seconds)",
      "countersReportTopOfTheHour": "Counters report top of the hour",
      "create": "Create Installation",
      "dataPlan": "Data Plan",
      "dataTransmissionAlarmBufferSeconds": "Data Transmission Alarm Buffer (seconds)",
      "digitalOutputRefreshRate": "Digital Output Refresh Rate (ms)",
      "dueMonth": "Due Month",
      "dueYear": "Due Year",
      "edit": "Edit Installation",
      "estimatedUsageKb": "Estimated Usage (kb)",
      "individualDigitalOutputControl": "Individual digital output control",
      "ioReportInterval": "IO report interval (seconds)",
      "latency": "Latency",
      "lead_lag": "Lead lag",
      "local_pump_controller": "Local pump controller",
      "many": "Installations",
      "maxAttemptsBeforeAlarm": "Max attempts before alarm",
      "maxNumberOfPumpsOn": "Max number of pumps on",
      "maxTemp": "Max temp",
      "minTemp": "Min temp",
      "modbusBaudRate": "Modbus baud rate",
      "modbusMode": "Modbus mode",
      "modbusParity": "Modbus parity",
      "modbusReadWriteInterval": "Modbus Read/Write Interval (ms)",
      "modbusTimeoutAlarmLevel": "Modbus timeout alarm level",
      "mode": "Mode",
      "noRtu": "no rtu set",
      "nocommAlarmBufferSeconds": "Nocomm alarm buffer (seconds)",
      "nocommAlertAgeSettings": "No comm alert settings (hours)",
      "none": "None",
      "one": "installation",
      "other": "{count} installations",
      "powerSettings": "Power settings",
      "pumpAckEnabled": "Pump ack enabled",
      "pumpSettings": "Pump settings",
      "reportUpperAnalogs": "Report upper analogs",
      "retailPrice": "Retail Price",
      "sendDataTransmissionClear": "Send Data Transmission Clear",
      "sendDoCommand": "Send do command",
      "sendNocommClear": "Send no comm clear",
      "signalStrength": "Signal Strength",
      "solar": "Solar",
      "startupDate": "Startup Date",
      "systemStatusReportInterval": "System status report interval  (seconds)",
      "totalNumberOfPumps": "Total number of pumps",
      "vbatHysteresis": "Vbat hysteresis",
      "vbatMinimum": "Vbat minimum",
      "vextBadValidTime": "Vext bad valid time",
      "vextGoodValidTime": "Vext good valid time",
      "vextMinimum": "Vext minimum",
      "wholesalePrice": "Wholesale Price"
    },
    "installationAlarmsLabel": "{installation_name} Alarms",
    "installationMap": {
      "noAddressForMap": "There are no installations with address set"
    },
    "installationPending": "Installation Pending",
    "installationText": "Installation",
    "installationlabel": "Installation Label",
    "installations": "Installations",
    "installed": "installed",
    "interval_in_minutes": "{count, plural, =1 {every minute} other {# minutes}}",
    "invalidItem": "Invalid {item}",
    "inventory": "inventory",
    "io": "I/O",
    "ipAddress": "{count, plural, =1 {IP address} other {IP addresses}}",
    "itemNotFound": "{item} not found",
    "itemUnknown": "{item} unknown",
    "item_near_me": "{item} near me",
    "item_not_availbale": "{item} not available",
    "items": "items",
    "keep_editing": "Keep Editing",
    "label": "Label",
    "last-comm": "last comm",
    "last-name": "last name",
    "last-power-reading": "last power reading",
    "last15min": "Last 15 minutes",
    "last2min": "Last 2 minutes",
    "last5min": "Last 5 minutes",
    "lastHour": "Last Hour",
    "lastName": "Last Name",
    "lastReading": "Last reading",
    "lastReadingCapitalized": "Last Reading",
    "lastUpdatedAt": "Last updated at",
    "lastname": "Last Name",
    "lastpumpreport": "Last Pump Report",
    "lastreading": "Last Reading",
    "lastreporttime": "Last Report Time",
    "latency": "Latency",
    "latitude": "Latitude",
    "lcd-busy": "lcd busy",
    "lcd-present": "lcd present",
    "lead-pump": "lead pump",
    "leak-detect": "leak detect",
    "leaveBlankNoAlarm": "Leave fields blank for no alarm",
    "left": "Left",
    "legacyControls": "Legacy Controls",
    "legacySite": "Legacy Site",
    "legacyView": "HTT Legacy View",
    "legend": "Legend",
    "level": "Level",
    "liftStation": "Lift Station",
    "liftstationsreport": "Lift Station Report",
    "list": "list",
    "list_mode": "List mode",
    "liters": "Liters",
    "loading": "Loading",
    "loadmoredata": "Load More Data",
    "locale": "Locale",
    "location": "Location",
    "log": "{count, plural, =1 {Log} other {Logs}}",
    "loggedInWithTempPassword": "You have logged in using temporary password. Please change your password",
    "logic": "Logic",
    "logicType": "Logic type",
    "logicalMessage": {
      "many": "Logical messages",
      "one": "Logical message",
      "rawInboundMessages": "Raw Inbound Messages",
      "rawOutboundMessages": "Raw Outbound Messages"
    },
    "login": "Login",
    "logout": "Logout",
    "longitude": "Longitude",
    "lookupTableValue": "Lookup Table Value",
    "lookupunits": "Lookup Units",
    "low": "Low",
    "low-battery": "low battery",
    "lowalarm": "Low Alarm",
    "lowbatteryalarm": "Low Battery.  Battery voltage is {battery_voltage}",
    "lowbatteryalarmclear": "Battery Voltage Ok.  Battery voltage is {battery_voltage}",
    "lowcal": "Low Calibration",
    "lower": "Lower",
    "lowhighcal": "Low/High Calibration",
    "lowhighval": "Low/High Value",
    "lowval": "Low Input Value",
    "lpm": "lpm",
    "manual": "Manual",
    "manual-run": "manual run",
    "manualcontrol": "manual control",
    "manufacturer": "{count, plural, =1 {Manufacturer} other {Manufacturers}}",
    "map": "map",
    "mapFor": "{item} Map",
    "mapLegend": "Map Legend",
    "mass-assign": "mass assign",
    "mass-decommission": "mass decommission",
    "mass-outage-for": "mass outage for {group}",
    "mass-outage-settings-description": "A mass outage will block any alarm/alert notifications to the group until it is clear.  If a number alarms/alerts equaling the start threshold are received for site in the group within the start outage window, an outage notification is triggered. If number of alarms/alerts less than or equal to the clear threshold are received from sites within the group during the clear outage window, the outage is cleared.",
    "master-pump": "master pump",
    "max": "Max",
    "maxAlarmOffTimeIs6hours": "Max alarm off time is 6 hours",
    "maximumAbbreviation": "Max.",
    "maxruntimeinmin": "Max Runtime (minutes)",
    "maxtime": "max time",
    "mdn": "mdn",
    "menu": "menu",
    "menuItemType": "Menu Item Type",
    "message": "Message",
    "messageLog": "Message Log",
    "messages": "Messages",
    "messagetype": "Message Type",
    "messaging": "Messaging",
    "messaging_title": "Messaging Data for {installation}",
    "method": "Method",
    "middleName": "Middle Name",
    "middlename": "Middle Name",
    "min": "Min",
    "minimumAbbreviation": "Min.",
    "mintime": "min time",
    "minuteAbbr": "min",
    "minutePerStarts": "mins/start",
    "minutes": "minutes",
    "minutes-abbr": "min",
    "minutesAbbreviation": "min",
    "minuteslatency": "minutes latency",
    "modbus": {
      "registerAddress": "Modbus register address",
      "registerBit": "Modbus register bit",
      "registerType": "Modbus register type",
      "slave": "Modbus slave",
      "slaves": "Modbus slaves"
    },
    "modbusalarm": "Modbus Alarm",
    "modbusalarmclear": "Modbus Alarm Clear",
    "model": "model",
    "modelNumber": "Model number",
    "modem": "modem",
    "mon": "Mon",
    "monday": "monday",
    "month": "month",
    "month_to_date": "Month to Date",
    "month_to_date_and_yesterday": "Month to Date and Yesterday",
    "monthly": "Monthly",
    "monthlygraph": "Monthly Graph",
    "months": "{number} Months",
    "more": "More",
    "mostRecent": "{limit} Most Recent",
    "multipleSitesFoundPleaseSendMoreDigits": "Multiple sites found.  Please send {num_digits} identifier digits",
    "multiplier": "Multiplier",
    "mustBeSet": "must be set",
    "mustequalpassword": "must equal password",
    "na": "n/a",
    "nag": "Nag",
    "nagInterval": "Nag Interval (Min)",
    "name": "Name",
    "navigateTo": "Navigate to {item}",
    "neighborhood": "Neighborhood",
    "neighborhoods": "Neighborhoods",
    "new": "New",
    "new-item": "new {item}",
    "new-site-version": "New Version Available.",
    "newAddress": "New Address",
    "newAlarmRule": "New Alarm Rule",
    "newHardwareInfo": "New Hardware Info",
    "newItem": "New {item}",
    "newSiteMessage": "New Site Wide Message",
    "newUser": "New User",
    "newalarmgroupcontactlink": "New Alarm Group Contact Link",
    "newanalog": "New Analog",
    "newanalogoutput": "New Analog Output",
    "newcontact": "New Contact",
    "newcounter": "New Counter",
    "newmessage": "New Message",
    "next": "Next",
    "no": "No",
    "no-advance-data-alarms": "0 advanced data alarm",
    "no-item": "No {item}",
    "no-items-found": "No {items} found",
    "no-notifications-sent": "no notifications sent",
    "no-reading": "No Reading Available",
    "noActiveAlarms": "no active alarms",
    "noAddress": "No Address",
    "noAddressLabel": "No suitable address label found",
    "noAddressSet": "No Address Set",
    "noData": "No data",
    "noDataAvailable": "No data available",
    "noDataFound": "No data found",
    "noDaySelected": "No day selected",
    "noDescription": "No Description Set",
    "noEmailAddress": "No Email Address",
    "noInstallationsFound": "No installations found within {distance} miles",
    "noItems": "No {items}",
    "noLatLng": "No Lat/Lng saved",
    "noModemSet": "No Modem Set",
    "noPhoneNumber": "No Phone Number",
    "noPhoneNumberOrEmailAddress": "No phone number or email address",
    "noRTUControllerConfigured": "No RTU controller configured",
    "noRtu": "No RTU Set",
    "noText": "No",
    "no_ITEM_reading": "No {item} reading available",
    "no_ITEM_to_display": "No {item} to display",
    "no_comm_alert_settings": "No comm alert settings",
    "nocomm": "Nocomm",
    "nocommalarm": "Unit has not reported since {last_datetime}",
    "nocommalertlimit": "No Communication Alert Limit",
    "none": "None",
    "normallyClosed": "Normally Closed",
    "normallyOpen": "Normally Open",
    "not-acknowledged": "not acknowledged",
    "not-available-abbr": "n/a",
    "notAcknowledged": "Not Acknowledged",
    "notActive": "Not Active",
    "notAlarm": "Not Alarm",
    "notApplicable": "N/A",
    "notAssigned": "Not Assigned",
    "notConnectedToCollector": "not connected to collector",
    "notInAlarm": "Not In Alarm",
    "notInstalled": "Not Installed",
    "notSet": "not set",
    "note": "{count, plural, =1 {Note} other {Notes}}",
    "notes": "notes",
    "notification-logs": "notification logs",
    "notification-settings": "notification settings",
    "now": "Now",
    "number": "Number",
    "number-of-items": "number of {item}",
    "number_label": "Number",
    "numbered-pump": "pump {number}",
    "numberlabel": "Number",
    "objectData": "{object} data",
    "odor": "Odor",
    "ofDataEndingAt": "Of Data Ending At",
    "offText": "Off",
    "offUntil": "Off until {time}",
    "offlabel": "off",
    "offline": "Offline",
    "offset": "Offset",
    "offuntil": "off until",
    "ok": "OK",
    "onPolarity": "On Polarity",
    "onText": "On",
    "onlabel": "on",
    "open": "Open",
    "operator": "operator",
    "options": "Options",
    "orText": "or",
    "organizationsContacts": "{org} Contacts",
    "otp": "One time password",
    "output": "Output",
    "over-pressure": "over pressure",
    "over-pressure-lockout-flag": "over pressure lockout flag",
    "overvoltage": "overvoltage",
    "owner": "owner",
    "p1_alarm_latch_active_false": "p1 alarm latch not active",
    "p1_alarm_latch_active_true": "p1 alarm latch active",
    "p1_alarm_latch_buzzer_active_false": "p1 alarm latch buzzer inactive",
    "p1_alarm_latch_buzzer_active_true": "p1 alarm latch buzzer active",
    "p1_alarm_protect_active_false": "p1 alarm protect clear",
    "p1_alarm_protect_active_true": "p1 alarm protect active",
    "p1_brownout_false": "p1 brownout alarm clear",
    "p1_brownout_true": "p1 brownout alarm",
    "p1_buzzer_active_false": "p1 buzzer inactive",
    "p1_buzzer_active_true": "p1 buzzer active",
    "p1_clock_error_false": "p1 clock error clear",
    "p1_clock_error_true": "p1 clock error",
    "p1_comm_valid_false": "p1 duplex comm normal",
    "p1_comm_valid_true": "p1 duplex comm lost",
    "p1_contactor_diagnostic_false": "p1 contactor diagnostic false",
    "p1_contactor_diagnostic_true": "p1 contactor diagnostic true",
    "p1_dip_switch_fault_false": "p1 dip switch fault clear",
    "p1_dip_switch_fault_true": "p1 dip switch fault",
    "p1_dry_remote_diagnostic_false": "p1 dry remote diagnostic false",
    "p1_dry_remote_diagnostic_true": "p1 dry remote diagnostic true",
    "p1_duplex_test_mode_false": "p1 duplex test mode false",
    "p1_duplex_test_mode_true": "p1 duplex test mode true",
    "p1_explosion_proof_enabled_false": "p1 explosion proof not enabled",
    "p1_explosion_proof_enabled_true": "p1 explosion proof enabled",
    "p1_high_level_alarm_false": "p1 high level alarm clear p1 or p2",
    "p1_high_level_alarm_pending_false": "p1 high level pending clear",
    "p1_high_level_alarm_pending_true": "p1 high level pending",
    "p1_high_level_alarm_true": "p1 high level alarm p1 or p2",
    "p1_lcd_busy_false": "p1 lcd busy false",
    "p1_lcd_busy_true": "p1 lcd busy true",
    "p1_lcd_present_false": "p1 lcd present false",
    "p1_lcd_present_true": "p1 lcd present true",
    "p1_lead_pump_false": "p1 lead pump false",
    "p1_lead_pump_true": "p1 lead pump true",
    "p1_leak_detect_false": "p1 leak detected clear",
    "p1_leak_detect_true": "p1 leak detected",
    "p1_manual_run_false": "p1 manul run off",
    "p1_manual_run_true": "p1 manual run on",
    "p1_master_pump_false": "p1 master pump false",
    "p1_master_pump_true": "p1 master pump true",
    "p1_over_pressure_false": "p1 over pressure clear",
    "p1_over_pressure_lockout_flag_false": "p1 over pressure lockout clear",
    "p1_over_pressure_lockout_flag_true": "p1 over pressure lockout",
    "p1_over_pressure_true": "p1 over pressure",
    "p1_overvoltage_false": "p1 overvoltage alarm clear",
    "p1_overvoltage_true": "p1 overvoltage alarm",
    "p1_power_delay_active_false": "p1 power delay not active",
    "p1_power_delay_active_true": "p1 power delay active",
    "p1_power_up_false": "p1 power up false",
    "p1_power_up_true": "p1 power up true",
    "p1_protect_plus_membrane_false": "p1 protect plus membrane false",
    "p1_protect_plus_membrane_true": "p1 protect plus membrane true",
    "p1_pump_active_false": "p1 pump active not set",
    "p1_pump_active_true": "p1 pump active set",
    "p1_pump_disabled_false": "p1 pump disabled false",
    "p1_pump_disabled_true": "p1 pump disabled true",
    "p1_pump_running_false": "p1 pump not running",
    "p1_pump_running_true": "p1 pump running",
    "p1_rd_op_latch_active_false": "p1 rd op latch active false",
    "p1_rd_op_latch_active_true": "p1 rd op latch active true",
    "p1_run_dry_false": "p1 run dry clear",
    "p1_run_dry_lockout_flag_false": "p1 run dry lockout clear",
    "p1_run_dry_lockout_flag_true": "p1 run dry lockout",
    "p1_run_dry_true": "p1 run dry",
    "p1_run_limit_flag_false": "p1 run limit clear",
    "p1_run_limit_flag_true": "p1 run limit alarm",
    "p1_wattmeter_calibrate_false": "p1 wattmeter calibrate false",
    "p1_wattmeter_calibrate_true": "p1 wattmeter calibrate true",
    "p1_wattmeter_healthy_false": "p1 wattmeter comm alarm",
    "p1_wattmeter_healthy_true": "p1 wattmeter comm alarm clear",
    "p1_wattmeter_valid_false": "p1 wattmeter valid false",
    "p1_wattmeter_valid_true": "p1 wattmeter valid true",
    "p1_wireless_alarm_false": "p1 wireless alarm clear",
    "p1_wireless_alarm_true": "p1 wireless alarm",
    "p1_wireless_false": "p1 wireless false",
    "p1_wireless_true": "p1 wireless true",
    "p2_alarm_latch_active_false": "p2 alarm latch not active",
    "p2_alarm_latch_active_true": "p2 alarm latch active",
    "p2_alarm_latch_buzzer_active_false": "p2 alarm latch buzzer inactive",
    "p2_alarm_latch_buzzer_active_true": "p2 alarm latch buzzer active",
    "p2_alarm_protect_active_false": "p2 alarm protect clear",
    "p2_alarm_protect_active_true": "p2 alarm protect active",
    "p2_brownout_false": "p2 brownout alarm clear",
    "p2_brownout_true": "p2 brownout alarm",
    "p2_buzzer_active_false": "p2 buzzer inactive",
    "p2_buzzer_active_true": "p2 buzzer active",
    "p2_clock_error_false": "p2 clock error clear",
    "p2_clock_error_true": "p2 clock error",
    "p2_comm_valid_false": "p2 duplex comm normal",
    "p2_comm_valid_true": "p2 duplex comm lost",
    "p2_contactor_diagnostic_false": "p2 contactor diagnostic false",
    "p2_contactor_diagnostic_true": "p2 contactor diagnostic true",
    "p2_dip_switch_fault_false": "p2 dip switch fault clear",
    "p2_dip_switch_fault_true": "p2 dip switch fault",
    "p2_dry_remote_diagnostic_false": "p2 dry remote diagnostic false",
    "p2_dry_remote_diagnostic_true": "p2 dry remote diagnostic true",
    "p2_duplex_test_mode_false": "p2 duplex test mode false",
    "p2_duplex_test_mode_true": "p2 duplex test mode true",
    "p2_explosion_proof_enabled_false": "p2 explosion proof not enabled",
    "p2_explosion_proof_enabled_true": "p2 explosion proof enabled",
    "p2_high_level_alarm_false": "p2 high level alarm clear p2 or p2",
    "p2_high_level_alarm_pending_false": "p2 high level pending clear",
    "p2_high_level_alarm_pending_true": "p2 high level pending",
    "p2_high_level_alarm_true": "p2 high level alarm p2 or p2",
    "p2_lcd_busy_false": "p2 lcd busy false",
    "p2_lcd_busy_true": "p2 lcd busy true",
    "p2_lcd_present_false": "p2 lcd present false",
    "p2_lcd_present_true": "p2 lcd present true",
    "p2_lead_pump_false": "p2 lead pump false",
    "p2_lead_pump_true": "p2 lead pump true",
    "p2_leak_detect_false": "p2 leak detected clear",
    "p2_leak_detect_true": "p2 leak detected",
    "p2_manual_run_false": "p2 manul run off",
    "p2_manual_run_true": "p2 manual run on",
    "p2_master_pump_false": "p2 master pump false",
    "p2_master_pump_true": "p2 master pump true",
    "p2_over_pressure_false": "p2 over pressure clear",
    "p2_over_pressure_lockout_flag_false": "p2 over pressure lockout clear",
    "p2_over_pressure_lockout_flag_true": "p2 over pressure lockout",
    "p2_over_pressure_true": "p2 over pressure",
    "p2_overvoltage_false": "p2 overvoltage alarm clear",
    "p2_overvoltage_true": "p2 overvoltage alarm",
    "p2_power_delay_active_false": "p2 power delay not active",
    "p2_power_delay_active_true": "p2 power delay active",
    "p2_power_up_false": "p2 power up false",
    "p2_power_up_true": "p2 power up true",
    "p2_protect_plus_membrane_false": "p2 protect plus membrane false",
    "p2_protect_plus_membrane_true": "p2 protect plus membrane true",
    "p2_pump_active_false": "p2 pump active not set",
    "p2_pump_active_true": "p2 pump active set",
    "p2_pump_disabled_false": "p2 pump disabled false",
    "p2_pump_disabled_true": "p2 pump disabled true",
    "p2_pump_running_false": "p2 pump not running",
    "p2_pump_running_true": "p2 pump running",
    "p2_rd_op_latch_active_false": "p2 rd op latch active false",
    "p2_rd_op_latch_active_true": "p2 rd op latch active true",
    "p2_run_dry_false": "p2 run dry clear",
    "p2_run_dry_lockout_flag_false": "p2 run dry lockout clear",
    "p2_run_dry_lockout_flag_true": "p2 run dry lockout",
    "p2_run_dry_true": "p2 run dry",
    "p2_run_limit_flag_false": "p2 run limit clear",
    "p2_run_limit_flag_true": "p2 run limit alarm",
    "p2_wattmeter_calibrate_false": "p2 wattmeter calibrate false",
    "p2_wattmeter_calibrate_true": "p2 wattmeter calibrate true",
    "p2_wattmeter_healthy_false": "p2 wattmeter comm alarm",
    "p2_wattmeter_healthy_true": "p2 wattmeter comm alarm clear",
    "p2_wattmeter_valid_false": "p2 wattmeter valid false",
    "p2_wattmeter_valid_true": "p2 wattmeter valid true",
    "p2_wireless_alarm_false": "p2 wireless alarm clear",
    "p2_wireless_alarm_true": "p2 wireless alarm",
    "p2_wireless_false": "p2 wireless false",
    "p2_wireless_true": "p2 wireless true",
    "p3_alarm_latch_active_false": "p3 alarm latch not active",
    "p3_alarm_latch_active_true": "p3 alarm latch active",
    "p3_alarm_latch_buzzer_active_false": "p3 alarm latch buzzer inactive",
    "p3_alarm_latch_buzzer_active_true": "p3 alarm latch buzzer active",
    "p3_alarm_protect_active_false": "p3 alarm protect clear",
    "p3_alarm_protect_active_true": "p3 alarm protect active",
    "p3_brownout_false": "p3 brownout alarm clear",
    "p3_brownout_true": "p3 brownout alarm",
    "p3_buzzer_active_false": "p3 buzzer inactive",
    "p3_buzzer_active_true": "p3 buzzer active",
    "p3_clock_error_false": "p3 clock error clear",
    "p3_clock_error_true": "p3 clock error",
    "p3_comm_valid_false": "p3 duplex comm normal",
    "p3_comm_valid_true": "p3 duplex comm lost",
    "p3_contactor_diagnostic_false": "p3 contactor diagnostic false",
    "p3_contactor_diagnostic_true": "p3 contactor diagnostic true",
    "p3_dip_switch_fault_false": "p3 dip switch fault clear",
    "p3_dip_switch_fault_true": "p3 dip switch fault",
    "p3_dry_remote_diagnostic_false": "p3 dry remote diagnostic false",
    "p3_dry_remote_diagnostic_true": "p3 dry remote diagnostic true",
    "p3_duplex_test_mode_false": "p3 duplex test mode false",
    "p3_duplex_test_mode_true": "p3 duplex test mode true",
    "p3_explosion_proof_enabled_false": "p3 explosion proof not enabled",
    "p3_explosion_proof_enabled_true": "p3 explosion proof enabled",
    "p3_high_level_alarm_false": "p3 high level alarm clear p3 or p2",
    "p3_high_level_alarm_pending_false": "p3 high level pending clear",
    "p3_high_level_alarm_pending_true": "p3 high level pending",
    "p3_high_level_alarm_true": "p3 high level alarm p3 or p2",
    "p3_lcd_busy_false": "p3 lcd busy false",
    "p3_lcd_busy_true": "p3 lcd busy true",
    "p3_lcd_present_false": "p3 lcd present false",
    "p3_lcd_present_true": "p3 lcd present true",
    "p3_lead_pump_false": "p3 lead pump false",
    "p3_lead_pump_true": "p3 lead pump true",
    "p3_leak_detect_false": "p3 leak detected clear",
    "p3_leak_detect_true": "p3 leak detected",
    "p3_manual_run_false": "p3 manul run off",
    "p3_manual_run_true": "p3 manual run on",
    "p3_master_pump_false": "p3 master pump false",
    "p3_master_pump_true": "p3 master pump true",
    "p3_over_pressure_false": "p3 over pressure clear",
    "p3_over_pressure_lockout_flag_false": "p3 over pressure lockout clear",
    "p3_over_pressure_lockout_flag_true": "p3 over pressure lockout",
    "p3_over_pressure_true": "p3 over pressure",
    "p3_overvoltage_false": "p3 overvoltage alarm clear",
    "p3_overvoltage_true": "p3 overvoltage alarm",
    "p3_power_delay_active_false": "p3 power delay not active",
    "p3_power_delay_active_true": "p3 power delay active",
    "p3_power_up_false": "p3 power up false",
    "p3_power_up_true": "p3 power up true",
    "p3_protect_plus_membrane_false": "p3 protect plus membrane false",
    "p3_protect_plus_membrane_true": "p3 protect plus membrane true",
    "p3_pump_active_false": "p3 pump active not set",
    "p3_pump_active_true": "p3 pump active set",
    "p3_pump_disabled_false": "p3 pump disabled false",
    "p3_pump_disabled_true": "p3 pump disabled true",
    "p3_pump_running_false": "p3 pump not running",
    "p3_pump_running_true": "p3 pump running",
    "p3_rd_op_latch_active_false": "p3 rd op latch active false",
    "p3_rd_op_latch_active_true": "p3 rd op latch active true",
    "p3_run_dry_false": "p3 run dry clear",
    "p3_run_dry_lockout_flag_false": "p3 run dry lockout clear",
    "p3_run_dry_lockout_flag_true": "p3 run dry lockout",
    "p3_run_dry_true": "p3 run dry",
    "p3_run_limit_flag_false": "p3 run limit clear",
    "p3_run_limit_flag_true": "p3 run limit alarm",
    "p3_wattmeter_calibrate_false": "p3 wattmeter calibrate false",
    "p3_wattmeter_calibrate_true": "p3 wattmeter calibrate true",
    "p3_wattmeter_healthy_false": "p3 wattmeter comm alarm",
    "p3_wattmeter_healthy_true": "p3 wattmeter comm alarm clear",
    "p3_wattmeter_valid_false": "p3 wattmeter valid false",
    "p3_wattmeter_valid_true": "p3 wattmeter valid true",
    "p3_wireless_alarm_false": "p3 wireless alarm clear",
    "p3_wireless_alarm_true": "p3 wireless alarm",
    "p3_wireless_false": "p3 wireless false",
    "p3_wireless_true": "p3 wireless true",
    "p4_alarm_latch_active_false": " p4 alarm latch not active",
    "p4_alarm_latch_active_true": " p4 alarm latch active",
    "p4_alarm_latch_buzzer_active_false": " p4 alarm latch buzzer inactive",
    "p4_alarm_latch_buzzer_active_true": " p4 alarm latch buzzer active",
    "p4_alarm_protect_active_false": " p4 alarm protect clear",
    "p4_alarm_protect_active_true": " p4 alarm protect active",
    "p4_brownout_false": " p4 brownout alarm clear",
    "p4_brownout_true": " p4 brownout alarm",
    "p4_buzzer_active_false": " p4 buzzer inactive",
    "p4_buzzer_active_true": " p4 buzzer active",
    "p4_clock_error_false": " p4 clock error clear",
    "p4_clock_error_true": " p4 clock error",
    "p4_comm_valid_false": " p4 duplex comm normal",
    "p4_comm_valid_true": " p4 duplex comm lost",
    "p4_contactor_diagnostic_false": " p4 contactor diagnostic false",
    "p4_contactor_diagnostic_true": " p4 contactor diagnostic true",
    "p4_dip_switch_fault_false": " p4 dip switch fault clear",
    "p4_dip_switch_fault_true": " p4 dip switch fault",
    "p4_dry_remote_diagnostic_false": " p4 dry remote diagnostic false",
    "p4_dry_remote_diagnostic_true": " p4 dry remote diagnostic true",
    "p4_duplex_test_mode_false": " p4 duplex test mode false",
    "p4_duplex_test_mode_true": " p4 duplex test mode true",
    "p4_explosion_proof_enabled_false": " p4 explosion proof not enabled",
    "p4_explosion_proof_enabled_true": " p4 explosion proof enabled",
    "p4_high_level_alarm_false": " p4 high level alarm clear  p4 or p2",
    "p4_high_level_alarm_pending_false": " p4 high level pending clear",
    "p4_high_level_alarm_pending_true": " p4 high level pending",
    "p4_high_level_alarm_true": " p4 high level alarm  p4 or p2",
    "p4_lcd_busy_false": " p4 lcd busy false",
    "p4_lcd_busy_true": " p4 lcd busy true",
    "p4_lcd_present_false": " p4 lcd present false",
    "p4_lcd_present_true": " p4 lcd present true",
    "p4_lead_pump_false": " p4 lead pump false",
    "p4_lead_pump_true": " p4 lead pump true",
    "p4_leak_detect_false": " p4 leak detected clear",
    "p4_leak_detect_true": " p4 leak detected",
    "p4_manual_run_false": " p4 manul run off",
    "p4_manual_run_true": " p4 manual run on",
    "p4_master_pump_false": " p4 master pump false",
    "p4_master_pump_true": " p4 master pump true",
    "p4_over_pressure_false": " p4 over pressure clear",
    "p4_over_pressure_lockout_flag_false": " p4 over pressure lockout clear",
    "p4_over_pressure_lockout_flag_true": " p4 over pressure lockout",
    "p4_over_pressure_true": " p4 over pressure",
    "p4_overvoltage_false": " p4 overvoltage alarm clear",
    "p4_overvoltage_true": " p4 overvoltage alarm",
    "p4_power_delay_active_false": " p4 power delay not active",
    "p4_power_delay_active_true": " p4 power delay active",
    "p4_power_up_false": " p4 power up false",
    "p4_power_up_true": " p4 power up true",
    "p4_protect_plus_membrane_false": " p4 protect plus membrane false",
    "p4_protect_plus_membrane_true": " p4 protect plus membrane true",
    "p4_pump_active_false": " p4 pump active not set",
    "p4_pump_active_true": " p4 pump active set",
    "p4_pump_disabled_false": " p4 pump disabled false",
    "p4_pump_disabled_true": " p4 pump disabled true",
    "p4_pump_running_false": " p4 pump not running",
    "p4_pump_running_true": " p4 pump running",
    "p4_rd_op_latch_active_false": " p4 rd op latch active false",
    "p4_rd_op_latch_active_true": " p4 rd op latch active true",
    "p4_run_dry_false": " p4 run dry clear",
    "p4_run_dry_lockout_flag_false": " p4 run dry lockout clear",
    "p4_run_dry_lockout_flag_true": " p4 run dry lockout",
    "p4_run_dry_true": " p4 run dry",
    "p4_run_limit_flag_false": " p4 run limit clear",
    "p4_run_limit_flag_true": " p4 run limit alarm",
    "p4_wattmeter_calibrate_false": " p4 wattmeter calibrate false",
    "p4_wattmeter_calibrate_true": " p4 wattmeter calibrate true",
    "p4_wattmeter_healthy_false": " p4 wattmeter comm alarm",
    "p4_wattmeter_healthy_true": " p4 wattmeter comm alarm clear",
    "p4_wattmeter_valid_false": " p4 wattmeter valid false",
    "p4_wattmeter_valid_true": " p4 wattmeter valid true",
    "p4_wireless_alarm_false": " p4 wireless alarm clear",
    "p4_wireless_alarm_true": " p4 wireless alarm",
    "p4_wireless_false": " p4 wireless false",
    "p4_wireless_true": " p4 wireless true",
    "packedperiodhours": "Packed Period Hours",
    "packedpulsecounters": "Packed Pulse Counters",
    "packedsamples": "Packed Samples",
    "page": "page",
    "pageItemsText": "{startNum} - {endNum} of {total}",
    "panel-description": "panel description",
    "panel-type": "panel type",
    "parent": "parent",
    "parsedValue": "Parsed Value",
    "password": "password",
    "password-change-success": "password changed",
    "password-confirmation": "password confirmation",
    "passwordConfirmation": "Re-type Password",
    "pending": "Pending",
    "per15minutes": "per 15 min",
    "perPulse": "per pulse",
    "permissionDenied": "Permission Denied",
    "permissions-level": "permissions level",
    "permissionsLevel": "Permissions Level",
    "phone-number": "phone number",
    "phone-numbers": "phone numbers",
    "phoneNumber": "Phone Number",
    "phonenumber": "Phone Number",
    "phonenumbers": "Phone Numbers",
    "plc": "PLC",
    "plc-register": {
      "address": "Address",
      "many": "Plc Registers",
      "mappedType": "Mapped Type",
      "name": "Name",
      "newPlcRegister": "New PLC Register",
      "number": "Number",
      "one": "plc register",
      "other": "{count} plc registers"
    },
    "plcaddress": "PLC Address",
    "plcregisters": "Plc Registers",
    "please-check-your-email-for-password-reset-link": "Please check your email for the link to reset your password.",
    "pleaseEnterTheTotalStartsAndTotalRuntimeForThisPump": "Please enter the total starts and total runtime for this pump:",
    "pleaseSelectDates": "Please select the two dates between which you would like to see the data",
    "pleaseSelectGuardian": "Please select a guardian",
    "pleaseSelectYear": "Please select a year.",
    "pleaseSelectYearAndMonth": "Please select a year and a month.",
    "polarity": "Polarity",
    "power-delay-active": "power delay active",
    "power-fail": "power fail",
    "power-fail-alert": "Power Fail Alert",
    "power-up": "power up",
    "powerType": "Power Type",
    "power_settings": "Power settings",
    "powered-by": "powered by",
    "powermode": "Power mode",
    "precision": "Precision",
    "processing": "Processing",
    "profile": "Profile",
    "protect-plus-membrane": "protect plus membrane",
    "pulse": "Pulse",
    "pulsecounters": "Pulse Counters",
    "pulsevalue": "Pulse Value",
    "pump": {
      "active": "Active",
      "amps": "Amps",
      "auto": "Auto",
      "averageRuntime": "Average runtime",
      "call": "Call",
      "callOff": "Call Off",
      "callOn": "Call On",
      "callibrationInfo": "Calibration Info",
      "enabled": "Enabled",
      "excessiveSingleRunSeconds": "Excessive Single Run Seconds",
      "flowPerMinute": "Flow Per Minute",
      "flowPerMinuteUnits": "Flow Per Minute Units",
      "flowRatePerMinute": "Flow Rate Per Minute",
      "flowRatePerMinuteUnits": "Flow Rate Per Minute Units",
      "gpm": "GPM",
      "hand": "Hand",
      "history": "History",
      "inactive": "Not Active",
      "installedAt": "Installed at",
      "installedOn": "Installed on",
      "many": "Pumps",
      "monthRuntime": "Month runtime",
      "offStatus": "Off Status",
      "onStatus": "On Status",
      "one": "pump",
      "other": "{count} pumps",
      "pump": "pump",
      "pumpFail": "Pump Fail",
      "reportStats": "Report Stats",
      "reportStatsFromEvents": "Report Stats From Events",
      "reportingPeriod": "Reporting Period",
      "runtime": "runtime",
      "sealFail": "Seal Fail",
      "starts": "starts",
      "status": "Status",
      "tempAlarm": "Temperature Alarm",
      "totalDiRuntime": "Total DI Runtime",
      "totalRuntime": "Total runtime",
      "totalStarts": "Total starts",
      "uninstalledAt": "Uninstalled at",
      "vibrationAlarm": "Vibration Alarm",
      "yesterdayRuntime": "Yesterday runtime",
      "zero": "Pump"
    },
    "pump-active": "pump active",
    "pump-cycles": "pump cycles",
    "pump-disabled": "pump disabled",
    "pump-num": "pump {num}",
    "pump-running": "pump running",
    "pump-runtime": "pump runtime",
    "pumpAbbreviation": "P{number}",
    "pumpAlarmText": "Pump Alarm",
    "pumpClearText": "Pump Clear",
    "pumpCorrelatedReading": "{count, plural, =1 {Pump correlated reading} other {Pump correlated readings}}",
    "pumpFail": "Pump Fail",
    "pumpFailure": "Pump Failure",
    "pumpGroup": {
      "many": "Pump groups",
      "maxOn": "Max on",
      "one": "Pump group"
    },
    "pumpHistory": "{count, plural, =1 {Pump history} other {Pump histories}}",
    "pumpMfg": "Pump Manufacturer",
    "pumpModel": "Pump model",
    "pumpOk": "Pump Ok",
    "pumpRuntime": "{pump} runtime",
    "pumpStarts": "{pump} starts",
    "pumpStats": "Pump Stats",
    "pumpStatus": "Pump Status",
    "pumpText": "Pump",
    "pumpType": "Pump Type",
    "pump_ack_enabled": "Pump ack enabled",
    "pumpcontrol": "pump control",
    "pumpfailure": "Pump failure",
    "pumpmaxruntimelabel": "{installationname} pumps max runtime in min",
    "pumpoff": "{pump_name} off",
    "pumpok": "Pump ok",
    "pumpon": "{pump_name} on",
    "pumpruntimealarm": "{name} runtime alarm.  {name} has been running for {runtime} minutes.",
    "pumpruntimealarmclear": "{name} stopped running.  {name} ran for {runtime} minutes.",
    "pumps": "pumps",
    "pumpsgraph": "Pumps Graph",
    "pumpsreport": "Pumps Report",
    "pumpstatistics": "Pump Statistics",
    "pumpstatsreport": "Pump Stats Report",
    "rain-counter": {
      "general_settings": "General Settings",
      "m": "M",
      "many": "Rain Counters",
      "name": "Name",
      "newRainCounter": "New Rain Counter",
      "number": "Number",
      "one": "rain counter",
      "other": "{count} rain counters",
      "units": "Units"
    },
    "rapidDrop": "Rapid Drop",
    "rapidDropAlarmEnabled": "Rapid Drop Alarm Enabled",
    "rapidDropExplanation": "Rapid drop alarm is triggered if a reading drops more than the given threshold within a 15-minute window",
    "rapidDropThreshold": "Rapid Drop Threshold",
    "rapidDropValue": "Rapid Drop Value",
    "rawValue": "Raw Value",
    "rd-op-latch-active": "rd op latch active",
    "reading": {
      "one": "Reading",
      "two": "Readings"
    },
    "readingType": "Reading Type",
    "readings": "Readings",
    "readingsBetweenDateRange": "Readings between these dates will be included in the generated Excel file",
    "readingsOffset": "Readings offset",
    "reason": "Reason",
    "recentActivity": "Recent activity",
    "recentAlarms": "Recent Alarms",
    "recentHistory": "Recent history",
    "record": "{count, plural, =1 {record} other {records}}",
    "reload": "reload",
    "reloads": "Reloads",
    "remoteenabled": "Remote Enabled",
    "rename": "Rename",
    "replace": "Replace",
    "replaceExistingGuardian": "Replace an Existing Guardian",
    "replacePump": "Replace pump",
    "replace_with": "Replace with",
    "report": {
      "many": "Reports",
      "name": "Report name",
      "one": "Report",
      "type": "Report type"
    },
    "reportOnly": "Report Only",
    "reportType": "Report Type",
    "reportcreatedon": "Report created on",
    "reportdowntimeafter": "Report Downtime After",
    "reporting": "Reporting",
    "reports": "reports",
    "requestanalogreading": "Request Analog Reading",
    "requestreading": "Request Reading",
    "required": "required",
    "requiredlength": "must be {length} characters long",
    "reset": "Reset",
    "resetFilters": "Reset Filters",
    "resetPassword": "Reset password",
    "resetUsersPassword": "Reset password for {user}",
    "resetpassword": "reset password",
    "resistor": "Resistor",
    "retrievingData": "Retrieving Data...",
    "retroactiveDate": "Retroactive date",
    "rma": "rma",
    "row-num": "row {num}",
    "rowsPerPage": "rows per page",
    "rtu": "RTU",
    "rtuController": "RTU controller",
    "rtuType": "RTU Type",
    "rtuUnit": "RTU unit",
    "rtu_unit": "RTU Unit #",
    "rule": "{count, plural, =1 {Rule} other {Rules}}",
    "rules": "rules",
    "run-dry": "run dry",
    "run-dry-lockout-flag": "run dry lockout flag",
    "run-limit-flag": "run limit flag",
    "runtime": "runtime",
    "runtimeGraph": "Runtime Graph",
    "runtimePerCycle": "Runtime per Cycle",
    "runtimePerStart": "Runtime per Start",
    "runtimegraph": "Runtime Graph",
    "runtimeinhours": "Runtime in hours",
    "runtimesPerStart": "{pump} Runtimes Per Start",
    "sa-site-settings": "sentry advisor site settings",
    "sam": {
      "latitude": "Latitude",
      "longitude": "Longitude",
      "message-type": "Message Type",
      "mobile-id": "Mobile Id",
      "mobile-id-type": "Mobile Id Type",
      "rtu-time": "Rtu Time",
      "server-time": "Server Time",
      "signal-strength": "Signal Strength",
      "status-history": "Status History"
    },
    "sat": "Sat",
    "saturation": "saturation",
    "saturationAbbreviation": "Sat.",
    "saturday": "saturday",
    "save": "save",
    "save-failed": "Save failed",
    "saved": "Saved",
    "savedItem": "Saved {item}",
    "saving": "Saving",
    "scale": "Scale",
    "scheduledCommand": "{count, plural, =1 {Scheduled command} other {Scheduled commands}}",
    "sealAlarm": "Seal Alarm",
    "sealClear": "Seal Clear",
    "sealFail": "Seal Fail",
    "search": "search",
    "search-for-item": "Search for {item}",
    "searchItem": "Search {item}",
    "searchingItem": "Searching {item} ...",
    "seconds": "seconds",
    "seconds-abbr": "secs",
    "selectAll": "Select All",
    "selectAnItem": "Select an {item}",
    "selectItem": "Select a {item}",
    "selected-items": "Selected {items}",
    "send": "Send",
    "send-alarm-notifications": "send alarm notifications",
    "send-reset-link": "send reset link",
    "sendAlarms": "Send Alarms",
    "sendAll": "Send All",
    "sendLogicalMessage": "Send Logical Message",
    "send_alarms": "Send alarms",
    "send_do_command": "Send DO command",
    "send_nocomm_clear": "Send nocomm clear",
    "sendalarm": "Send Alarm",
    "sendalarmclear": "Send Alarm Clear",
    "sendalarms": "Send Alarms",
    "sendallalarms": "Send All Alarms",
    "sending": "Sending",
    "sendlogs": "Send Logs",
    "sendpumpstatuswithalarm": "Send Pump Status With Alarm",
    "sendtime": "Send Time",
    "sensoroutput": "Sensor Output Type",
    "sentry-advisor": "modem",
    "sentry-advisor-message": "{count, plural, =1 {sentry advisor message} other {sentry advisor messages}}",
    "sentry-advisor-model": "modem model",
    "sentry-advisor-models": "modem models",
    "sentry-advisor-title": "Sentry Advisor",
    "sentry-advisors": "modems",
    "sentry-advisors-count": "{count, plural, =1 {# modem} other {# modems}}",
    "sentry-general-alarm-clear": "sentry-general-alarm-clear",
    "sentryAdvisorModbusData": {
      "cum_record_amps_max": "P1 Maximum Amps",
      "cum_record_amps_min": "P1 Minimum Amps",
      "cum_record_amps_sum": "not shown 5",
      "cum_record_avg_amps": "P1 Average Amps",
      "cum_record_avg_time": "not shown 7",
      "cum_record_avg_volts": "P1 Average Voltage",
      "cum_record_avg_watts": "P1 Average Wattage",
      "cum_record_cycles": "P1 Cycles",
      "cum_record_duplex_system": "Duplex System?",
      "cum_record_last_run_time": "P1 Last Runtime",
      "cum_record_man_cycles": "P1 Manual Run Cycles",
      "cum_record_man_run_time": "P1 Manual Runtime",
      "cum_record_max_run_time": "P1 Maximum Runtime",
      "cum_record_min_run_time": "P1 Minimum Runtime",
      "cum_record_run_time": "P1 Runtime",
      "cum_record_volts_max": "P1 Maximum Voltage",
      "cum_record_volts_min": "P1 Minimum Voltage",
      "cum_record_volts_sum": "not shown 3",
      "cum_record_watts_max": "P1 Maximum Wattage",
      "cum_record_watts_min": "P1 Minimum Wattage",
      "cum_record_watts_sum": "not shown 2",
      "current_flags": "P1 Event Flags",
      "dip_switch_setting": "DIP Switch Setting",
      "ee_prom_constants_alarm_delay": "Alarm Delay",
      "ee_prom_constants_duplex_alternate_time": "Duplex Alternate Time",
      "ee_prom_constants_duplex_mode": "Duplex Mode?",
      "ee_prom_constants_flags": "User Settings",
      "ee_prom_constants_hi_watt_limit": "Hi Watt Limit",
      "ee_prom_constants_i_lsb": "Amps Cal Factor",
      "ee_prom_constants_low_watt_limit": "Low Watt Limit",
      "ee_prom_constants_power_delay": "Power Delay",
      "ee_prom_constants_run_limit": "Run Limit",
      "ee_prom_constants_v_lsb": "Volts Cal Factor",
      "ee_prom_constants_wgain": "Wattage Cal Factor",
      "protect_limits_hi_volt_off": "Overvoltage Trip Voltage",
      "protect_limits_hi_volt_on": "Overvoltage Recover Voltage",
      "protect_limits_low_volt_off": "Brownout Trip Voltage",
      "protect_limits_low_volt_on": "Brownout Recover Voltage",
      "pump_led_mask": "Pump LED Status",
      "relay_mask": "Output Relay Status",
      "remote_cum_record_amps_max": "P2 Maximum Amps",
      "remote_cum_record_amps_min": "P2 Minimum Amps",
      "remote_cum_record_amps_sum": "not shown 14",
      "remote_cum_record_avg_amps": "P2 Average Amps",
      "remote_cum_record_avg_time": "not shown 11",
      "remote_cum_record_avg_volts": "P2 Average Voltage",
      "remote_cum_record_avg_watts": "P2 Average Wattage",
      "remote_cum_record_cycles": "P2 Cycles",
      "remote_cum_record_duplex_system": "Duplex System?",
      "remote_cum_record_last_run_time": "P2 Last Runtime",
      "remote_cum_record_man_avg_time": "not shown 12",
      "remote_cum_record_man_cycles": "P2 Manual Run Cycles",
      "remote_cum_record_man_run_time": "P2 Manual Runtime",
      "remote_cum_record_max_run_time": "P2 Maximum Runtime",
      "remote_cum_record_min_run_time": "P2 Minimum Runtime",
      "remote_cum_record_run_time": "P2 Runtime",
      "remote_cum_record_volts_max": "P2 Maximum Voltage",
      "remote_cum_record_volts_min": "P2 Minimum Voltage",
      "remote_cum_record_volts_sum": "not shown 13",
      "remote_cum_record_watts_max": "P2 Maximum Watts",
      "remote_cum_record_watts_min": "P2 Minimum Watts",
      "remote_cum_record_watts_sum": "not shown 15",
      "remote_current_flags": "P2 Event Flags",
      "remote_wattmeter_data_current_average": "P2 Amps",
      "remote_wattmeter_data_voltage_average": "P2 Volts",
      "remote_wattmeter_data_wattage": "P2 Watts",
      "software_version": "Software Version",
      "trouble_msg_id": "Trouble Msg ID",
      "user_led_mask": "User LED Status",
      "wattmeter_data_current_average": "P1 Amps",
      "wattmeter_data_voltage_average": "P1 Volts",
      "wattmeter_data_wattage": "P1 Watts"
    },
    "serial-number": "serial number",
    "serialNumber": "Serial Number",
    "serialNumberAbbr": "SN",
    "serialNumberNotSet": "Serial Number Not Set",
    "server": "server",
    "service-log": "service log",
    "service-provider": "service provider",
    "serviceLog": "{count, plural, other {service logs}}",
    "servicer": "Servicer",
    "set": "set",
    "setAddress": "set address",
    "setAlarmLevels": "Set Alarm Levels",
    "setAsDefaultTab": "Set as default tab",
    "setThresholdsLabel": "{inst}: Set {analog} Thresholds",
    "setValueUnits": "Set to {value} {units}",
    "setalarmthresholds": "Set Alarm Thresholds",
    "setoutput": "Set Output",
    "setthresholds": "Set Thresholds",
    "settings": "Settings",
    "settingsChanged": "settings changed",
    "shift": "shift",
    "shift-days": "shift days",
    "shift-time": "shift time",
    "shiftEnd": "Shift End",
    "shiftStart": "Shift Start",
    "shiftendtime": "Shift End Time",
    "shiftstarttime": "Shift Start Time",
    "show": "Show",
    "showgpgpumpstats": "Show GPG Pump Status",
    "showthresholdsongraph": "Show Thresholds On Graph",
    "sign-in": "sign in",
    "sign-out": "sign out",
    "signal-strength": "signal strength",
    "signalStrength": "Signal Strength",
    "signalstrength": "Signal Strength",
    "signout": "Sign out",
    "simplex": "Simplex",
    "site": "site",
    "siteNotFound": "Site {uid} not found",
    "siteSettings": "Site Settings",
    "sites": "sites",
    "sites-group": "sites group",
    "sites-list": "sites list",
    "sites-map": "sites map",
    "sitesMap": "Sites Map",
    "skip": "skip",
    "slot": "Slot",
    "softwarereset": "Software Reset",
    "solar": "Solar",
    "stackup": "Stackup",
    "standard": "standard",
    "start": "start",
    "start-outage-threshold": "start outage threshold (alarms)",
    "start-outage-window": "start outage window (minutes)",
    "startDate": "Start Date",
    "startDatetime": "Start Datetime",
    "startReadings": "Start Readings",
    "startofday": "Start of Day",
    "starts": "starts",
    "startsAbbr": "starts",
    "startsAndRuntimeFromEvents": "Starts and runtime from events",
    "startsAndRuntimeGraph": "Starts and Runtime Graph",
    "startsGraph": "Starts Graph",
    "startsPerRuntime": "Starts per runtime",
    "startsgraph": "Starts Graph",
    "statistics": "statistics",
    "statisticsDetail": "Statistics Detail",
    "status": "status",
    "statusAlarm": "Status Alarm",
    "statusAlarmClear": "Status Alarm Clear",
    "statusUnknown": "Status Unknown",
    "subDistributor": "Sub-distributor",
    "subDistributors": "Sub-distributors",
    "subGroups": "Sub groups",
    "subject": "subject",
    "submit": "Submit",
    "successfully_saved_MODEL_NAME": "Successfully saved {model} {name}",
    "summary": "Summary",
    "sun": "Sun",
    "sunday": "sunday",
    "supervisor": "supervisor",
    "supportnumber": "For Support Call 8774884882",
    "surname": "Surname",
    "suspend": "Suspend",
    "suspendMessage": "Would you like to suspend this alarm recipient?",
    "suspended": "Suspended",
    "swap": "Swap {what}",
    "swap-item": "swap {item}",
    "system-unavailable": "System currently unavailable",
    "tabItem": {
      "name": "Tab name",
      "tabType": "Tab type"
    },
    "table": "table",
    "technical-support-log": "technical support log",
    "tempAlarm": "Temperature Alarm",
    "tempClear": "Temperature Clear",
    "tempFail": "Temp Fail",
    "template": "template",
    "terms-of-service": "Terms of Service",
    "test": "Test",
    "testFailed": "Test Failed",
    "testPending": "Test Pending",
    "testSent": "Test Sent",
    "testalarm": "This is a test alarm for {contact}",
    "text": "Text",
    "text_display": "Text Display",
    "textandvoice": "Text And Voice",
    "threshold1": "Threshold 1",
    "threshold2": "Threshold 2",
    "threshold3": "Threshold 3",
    "threshold4": "Threshold 4",
    "threshold5": "Threshold 5",
    "threshold6": "Threshold 6",
    "threshold7": "Threshold 7",
    "threshold8": "Threshold 8",
    "thresholds": "Thresholds",
    "thu": "Thu",
    "thursday": "thursday",
    "time": "Time",
    "time-of-day": "time of day",
    "timeOfTheDay": "Time of the day",
    "timeframe-cycles": "{timeframe} cycles",
    "timeframe-runtime": "{timeframe} runtime",
    "timelabel": "Time",
    "timeofday": "Time of Day",
    "timezone": "Timezone",
    "title": "Title",
    "to": "To",
    "toText": "to",
    "today": "today",
    "top": "Top",
    "tos": "Terms of Service",
    "tos-agree": "I have read and agree to the terms of service.",
    "total": "Total",
    "total-runtime": "total runtime",
    "total_number_of_pumps": "Total number of pumps",
    "totalendofday": "Total End of Day",
    "totals": "Totals",
    "totalstartofday": "Total Start of Day",
    "transmissionType": "Transmission Type",
    "transmitter": "Transmitter",
    "trendingValue": "Trending Value",
    "trigger": "{count, plural, =1 {Trigger} other {Triggers}}",
    "truetext": "True Text",
    "tue": "Tue",
    "tuesday": "tuesday",
    "turbidity": "Turbidity",
    "turnAlarmsOff": "turn alarms off",
    "turnedOff": "Turned off",
    "turnedOn": "Turned On",
    "twoFactorAuthentication": "Two factor authentication",
    "type": "Type",
    "typeToSearch": "Type to search",
    "types": "types",
    "unSetAsDefaultTab": "Remove as default tab",
    "unavailable": "Unavailable",
    "undo": "Undo",
    "uninstall": "uninstall",
    "unitTypes": "Unit Types",
    "unitidentification": "Unit Identification",
    "units": "Units",
    "unknown": "unknown",
    "unknown-status": "unknown status",
    "unknownMessage": {
      "many": "Unknown messages",
      "one": "Unknown message",
      "reprocess": "Reprocess"
    },
    "unknownSmsCommand": "Unknown Command.  Text ack to acknowledge alarms.",
    "unknownText": "unknown",
    "unknownTransmissionType": "Unknown Transmission Type",
    "update": "Update",
    "updateItem": "Update {item}",
    "updated-items": "Updated {items}",
    "upload-template": "upload completed template",
    "uploadImage": "upload image",
    "uploadNewDistributionFile": "Upload a new distribution file",
    "uploadPicture": "Upload Picture",
    "upper": "Upper",
    "useMyCurrentGpsLocation": "Use My Current GPS Location",
    "user": {
      "all-users": "all users",
      "group-users": "{group} users",
      "many": "users",
      "one": "user",
      "username": "username"
    },
    "user-has-no-contact-methods": "user has no emails addresses or phone numbers set",
    "user-manual": "User Manual",
    "userHasNoContactMethods": "User does not have any phone number or email addresses",
    "userMustChangePasswordOnNextLogin": "User must change password on next login",
    "userTurnedOffAlarms": "{contact} turned alarms off until {off_until}",
    "userTurnedOnAlarms": "{contact} turned alarms on",
    "usereadingdatetime": "Use Reading Datetime",
    "username": "username",
    "usersHeader": "{contactParent} Users",
    "value": "value",
    "valve": {
      "closed": "Closed",
      "many": "Valves",
      "newInput": "New Input",
      "one": "Valve",
      "open": "Open",
      "openPosition": "Open Position",
      "other": "{count} valves",
      "reportStats": "Report Stats",
      "reportStatsFromEvents": "Report Stats From Events",
      "reportingPeriod": "Reporting Period"
    },
    "valveclose": "{valve_name} close",
    "valvecontrol": "valve control",
    "valveopen": "{valve_name} open",
    "valvesgraph": "Valves Graph",
    "vibrationAlarm": "Vibration Alarm",
    "vibrationClear": "Vibration Clear",
    "vibrationFail": "Vibration Fail",
    "view": "view",
    "view-all-advanced-data": "view all advanced data",
    "viewAll": "View all",
    "viewAllL": "View All {items}",
    "viewGuardians": "View Guardians",
    "viewImage": "view image",
    "viewMap": "View Map",
    "viewSite": "View Site",
    "viewer": "viewer",
    "voice": "Voice",
    "voltage": "Voltage",
    "volts": "Volts",
    "warning": "Warning",
    "wattage": "wattage",
    "wattmeter-alarm-delay": "Wattmeter Alarm Delay (s)",
    "wattmeter-calibrate": "wattmeter calibrate",
    "wattmeter-healthy": "wattmeter healthy",
    "wattmeter-valid": "wattmeter valid",
    "webapp-manual": "Webapp manual",
    "wed": "Wed",
    "wednesday": "wednesday",
    "week": "week",
    "weekly": "Weekly",
    "weeks": "{number} Weeks",
    "whenAreTheReadingsRecorded": "When are the readings recorded?",
    "which-item": "which {item}",
    "which-sentry-advisor-to-install": "which advisor to install?",
    "width": "Width",
    "wireless": "wireless",
    "wireless-alarm": "wireless alarm",
    "wouldYouLikeToAcknowledgeThisAlarm": "Would you like to acknowledge this alarm?",
    "year": "Year",
    "yearText": "Year",
    "yearly": "Yearly",
    "yes": "Yes",
    "yesText": "Yes",
    "yesterday": "Yesterday",
    "yesterday-runtime": "yesterday runtime",
    "youHaveItem": "You have 1 {items}",
    "youHaveItems": "You have {numOfItems} {items}",
    "youHaveNoItems": "You have no {items}"
  }]];
  _exports.default = _default;
});